import { useContext } from "react";
import { getRecipeData } from "./utils/getRecipeData";
import { RecipesContext } from "../../App";
import Errorpage from "../Errorpage/Errorpage";
import { Link } from "react-router-dom";
import CheckableCard from "../../components/Card/CheckableCard";

const RecipePage: React.FC = () => {
  const recipes = useContext(RecipesContext);
  const slug = window.location.pathname.split("/").pop() as string;
  const recipe = getRecipeData(recipes, slug);

  if (recipe === undefined) return <Errorpage />;

  console.log(recipe);

  return (
    <div className={"min-h-screen md:pt-10"}>
      <div className={"container relative mx-auto w-full lg:w-8/12"}>
        <div
          className="container relative h-44 w-full overflow-hidden bg-cover bg-center shadow-sm sm:rounded-xl md:h-64"
          style={{ backgroundImage: 'url("' + recipe.thumbnail + '")' }}
        >
          <Link to={"/"}>
            <div className="absolute left-4 top-4 h-10 w-10 rounded-xl bg-white text-center shadow">
              <i className="fa-solid fa-chevron-left relative top-2"></i>
            </div>
          </Link>
        </div>
        <div className="relative">
          <div className={"z-1O relative -top-8 mx-auto w-fit rounded-xl bg-white px-10 py-3 text-center shadow-sm"}>
            <p className="inline-block">
              <i className="fa-solid fa-fire-burner mr-1 text-slate-300"></i> {recipe.cooking_duration}
            </p>
            <p className="mx-8 inline-block">
              <i className="fa-solid fa-blender mr-1 text-slate-300"></i> {recipe.preparation_duration}
            </p>
            <p className="inline-block">
              <i className="fa-solid fa-user mr-1 text-slate-300"></i> {recipe.nb_person}
            </p>
          </div>
        </div>
        <div className="container px-4 pb-4">
          <h1 className="text-2xl font-medium">{recipe.title}</h1>
          <div className="h-1 w-10 bg-orange"></div>
          {recipe.additional_information !== null && (
            <>
              <h2 className={"pb-2 pt-6 text-lg font-medium"}>Informations additionnelles</h2>
              <div className="flex w-full rounded-xl border border-orange bg-orange/10 p-4 shadow-sm">
                <div className="flex items-center justify-center">
                  <i className="fa-solid fa-circle-info mr-4 text-orange"></i>
                </div>
                <p>{recipe.additional_information}</p>
              </div>
            </>
          )}
          <h2 className={"pb-2 pt-6 text-lg font-medium"}>
            Ingrédients{" "}
            <span className="relative bottom-0.5 text-xs font-normal text-slate-400">({recipe.ingredients.length})</span>
          </h2>
          <div className="flex-wrap sm:flex">
            {recipe.ingredients.map((ingredient: string, id: string) => (
              <CheckableCard text={ingredient} key={id} />
            ))}
          </div>
          <h2 className={"pb-2 pt-6 text-lg font-medium"}>
            Étapes <span className="relative bottom-0.5 text-xs font-normal text-slate-400">({recipe.steps.length})</span>
          </h2>
          {recipe.steps.map((step: string, id: string) => (
            <CheckableCard text={step} key={id} fullBackground />
          ))}
          <div className="pb-2 pt-16 text-center text-orange">
            <i className="fa-solid fa-carrot text-2xl"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipePage;
